<template>
  <div class="policy">
    <CodexPolicy/>
    <CodexFooter/>
  </div>
</template>

<script>
// @ is an alias to /src
import CodexPolicy from '../components/Codex-policy'
import CodexFooter from "../components/Codex-footer"

export default {
  name: 'PolicyView',
  components: {
    CodexPolicy,
    CodexFooter
  }
}
</script>
